import React, {Component} from 'react';
import '../styles/Projects.css'
import AboutProject from "./AboutProject";
// import BubblesGenerator from "./BubblesGenerator";

class Projects extends Component{
    state = {
        index: 0,
    };

    sites = [
        {
            name: "GoGoGifs",
            url: "https://gogogifs.netlify.app",
            image: "./images/gogogifs.jpg",
            github: "",
            tools: [
                {
                    name: "React",
                    path: "./images/react.png"
                },
                {
                    name: "Giphy",
                    path:  "./images/giphy-logo.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            GoGoGifs is a fun gif generator that allows a user to search for gifs by topics and ratings. 
            `,
            challenges: `
            Finding a good way to keep new gifs ready for when a user presses the "Next Gif" button. It still needs work since it can still "run out" of
            gifs, but creating a capped amount was the best solution so it does not over populate new gifs.
            `
        },
        {
            name: "Pics",
            url: "https://picsapp.netlify.com",
            image: "./images/picapp.png",
            github: "",
            tools: [
                {
                    name: "React",
                    path: "./images/react.png"
                },
                {
                    name: "Unsplash",
                    path:  "./images/unsplash.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            Pics is a sleek photograph application built using the Unsplash API and React.js. 
            This application allows users to search and view over a million stock 
            photos from over 110,000 contributing photographers.
            `,
            challenges: `
            One of the biggest challenges with this app was creating the pagination from scratch.
            I did not want to use a library to handle this so I could get practice on how to implement this
            feature from scratch. There are definitely some design issues I would like to correct, but I
            am happy with the way it works for now.
            
            Another challenge I faced was creating an infinite scroll when you view an artists photo collection. 
            I would like to refactor this feature to recycle the DOM instead of
            continuously adding new DOM elements as you scroll. For now this works with this simple app.
            `
        },
        {
            name: "Digital",
            url: "https://webjams.netlify.com",
            image: "./images/digital.png",
            github: "https://github.com/coltonteefy/web-jams",
            tools: [
                {
                    name: "React",
                    path: "./images/react.png"
                },
                {
                    name: "Tone.js",
                    path:  "./images/tonejs.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            Digital is a web based synthesizer built using Tone.js and React.js. 
            This application is a fun tool that allows users to explore sounds, 
            practice keyboard, and simple just have some fun with music.
            `,
            challenges: `
            One of the major challenges with this app I faced was dealing with properly destroying
            the audio context and loading in new effects as you turn them on. I am sure there is a much
            better way to accomplish this, but I have not figured it out yet. 
            
            Another bug I still have not fixed is being able to hold the notes. Right now if you hold down the
            keys it will just start repeating.
            
            I also need to fix the resizing of the keyboard section when you shrink the page. 
            `
        },
        {
            name: "Big Screens",
            url: "https://bigscreens.netlify.com",
            image: "./images/bigscreens.png",
            github: "",
            tools: [
                {
                    name: "React",
                    path: "./images/react.png"
                },
                {
                    name: "The Movie Database",
                    path:  "./images/tmdb.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            Big Screens is a Netflix style mock-up built using React and TMDB.
            `,
            challenges: `
            A huge challenge I faced with this app was trying to make the details drop down work just right.
            For now I have created it to pop up at the bottom like a modal, but this is not the way I wish to
            do things. I will have to refactor my designs in order to accomplish this goal.
            Another issue is that I need to create the trending page.
            `
        },
        {
            name: "Med Assist",
            url: "https://medassistproject.herokuapp.com/login",
            image: "./images/medassist.png",
            github: "https://github.com/coltonteefy/med-assist",
            tools: [
                {
                    name: "Angular",
                    path: "./images/angular.png"
                },
                {
                    name: "Heroku",
                    path:  "./images/heroku.png"
                },
                {
                    name: "MongoDB",
                    path:  "./images/mongo.png"
                },
                {
                    name: "AWS",
                    path:  "./images/aws.png"
                }
            ],
            about: `
            Med Assist is my senior project that was built using Angular, MongoDB, AWS, and Heroku.
            This application serves as a portal for patients to easily keep track of/schedule appointments, 
            medicine refill dates, documents, and instant access to doctor communication.
            It also has a doctor view where they can easily maintain patient records and search patient history to help
            develop the most effect plan to help each patient with their medical needs.
            `,
            challenges: `
            There were a few major issues we faced during this project.
            The first was how we would store user information. We needed to store a users personal information,
            medical history, calendar events, prescriptions, and medical reports. We decided on using MongoDB
            to store all of the users information and AWS to store any images. This allowed for us to use
            Node.js to create our API that handled all of these users events. 
            
            Another major challenge was creating a live chat between a user and a doctor.
            At the end of the project we managed to create a new socket anytime a user logged on and would
            notify them when a doctor was ready for chat. We are not sure about a long term solution and 
            needed more time to research best practices for using web sockets.
            We wanted to store a users conversation but went against that for this project 
            so we did not waste and space on our database.
            
            The last challenges we faced were understanding how to update our database with the correct user
            information.
            
            `
        },
        {
            name: "Star Wars",
            url: "https://coltonstarwars.netlify.com",
            image: "./images/starwars.png",
            github: "https://github.com/coltonteefy/starwars",
            tools: [
                {
                    name: "React",
                    path: "./images/react.png"
                },
                {
                    name: "SWAPI",
                    path:  "./images/swapi.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            This is a simple Star Wars application built using React and the Star Wars API(SWAPI).
            The main purpose of this tool is to allow users to deep dive into each Star Wars film details.
            `,
            challenges: `
            The biggest challenge with this application was figuring out how to load all of the film
            details and character images asynchronously at the same time. Luckily Promise.all() 
            saved the day. 
            
            I also learned the hard way that async await does not wait while you map an object. 
            I still have lots of work to do with this app in terms of details, but I am happy with the progress so far.
            `
        },
        {
            name: "Slices",
            url: "https://slices.netlify.com/",
            image: "./images/slices.png",
            github: "https://github.com/coltonteefy/cafeProject",
            tools: [
                {
                    name: "Angular",
                    path: "./images/angular.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                },
                {
                    name: "PayPal",
                    path:  "./images/paypal.png"
                }

            ],
            about: `
            Slices is a project I built for my Introduction to Software Development class back in 2017.
            This application was built using Angular and implemented a PayPal payment plugin. 
            This application is a generic food ordering system that allows users to view food items, add them to a cart,
            and purchase those items. Once the food items were purchased, these orders were sent to the kitchen side portal
            in order to cook and notify clients when their food was ready for pick up.
            `,
            challenges: `
            The biggest challenge I face was creating a cart and sending all of the cart items to the kitchen side
            view when a user ordered food. I was able to accomplish this by creating a store and subscribing to this store
            to watch for any updates with a users order. this made it extremely easy to gather the data and send
            all of the information I needed to the kitchen to begin working on orders as they came in.
            
            Another issue I had was dealing with payments. I chose to use PayPal for this problem. I would like to
            explore other options for this issue but for now the PayPal plugin works fine. 
            `
        },
        {
            name: "Good Boy",
            url: "https://immense-inlet-58502.herokuapp.com",
            image: "./images/goodboy.png",
            github: "https://github.com/coltonteefy/goodBoy",
            tools: [
                {
                    name: "Angular",
                    path: "./images/angular.png"
                },
                {
                    name: "Google Maps",
                    path:  "./images/maps.png"
                },
                {
                    name: "Heroku",
                    path:  "./images/heroku.png"
                }
            ],
            about: `
            Good Boy is a project I built for my User Interface class using Angular, Google Maps, and Heroku. 
            This application keeps track of a users pet activities, allows a user to schedule events 
            through a calendar, view daily events, and search for nearby veterinarians/parks using the Google Maps API.
            `,
            challenges: `
            The biggest challenge during this app was using the Google Maps API and creating calendar events
            for each pet.
            `
        },
        {
            name: "Meters",
            url: "https://meters.netlify.com/",
            image: "./images/meters.png",
            github: "https://github.com/coltonteefy/meters",
            tools: [
                {
                    name: "VanillaJS",
                    path: "./images/vanillajs.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            Meters is one of the first JS projects I developed with one of my friends, Noah. We just wanted to learn Javascript
            and decided to make a simple game. The object of the game is to stop the meter inside of the blocked space until you die.
            `,
            challenges: `
            The biggest challenge during this project was learning Javascript, making the meter go back and forth, and
            making the music. The game still has a bug where it will stop outside the block and pass. It will also stop just just inside and fail.
            `
        },
        {
            name: "Al Attack",
            url: "https://alattack.netlify.com/",
            image: "./images/alattack.png",
            github: "https://github.com/coltonteefy/alattack",
            tools: [
                {
                    name: "VanillaJS",
                    path: "./images/vanillajs.png"
                },
                {
                    name: "Netlify",
                    path:  "./images/netlify.png"
                }
            ],
            about: `
            Al Attack is the first JS project I developed with one of my friends, Noah. We just wanted to learn Javascript
            and decided to make a simple game. The object of the game is to not get it by the aliens attacks.
            `,
            challenges: `
            The biggest challenge during this project was learning Javascript, designing the game layout, building the characters, and
            making the music. I would have loved to use sprites and make the character run much more smooth and I would have loved to have
            the aliens shoot hear seeker bombs at you.
            `
        }
    ];

    static goToDemo(url) {
        window.open(url);
    }

    render() {
        return(
            <div className="projects-wrapper">
                <div className="project-label">
                    {/*<h1>projects</h1>*/}
                    <span>p</span>
                    <span>r</span>
                    <span>o</span>
                    <span>j</span>
                    <span>e</span>
                    <span>c</span>
                    <span>t</span>
                    <span>s</span>
                </div>

                {/*<BubblesGenerator/>*/}

                <div className="projects-container">
                    {this.sites.map((site, index) => {
                        return (
                            <div className="projects" key={"projects"+index}>
                                <div key={index}>
                                    <div className="preview" style={{background: `url(${site.image})`}}/>
                                    <div className="panel">
                                        <h2 className="project-name">{site.name}</h2>
                                        <span>
                                        <button className="demo-button" type="button" onClick={() => {
                                            Projects.goToDemo(site.url)
                                        }}>Demo <i className="fas fa-arrow-right"/></button>
                                            {
                                                site.github !== "" &&
                                                (
                                                    <button className="github-button" type="button" onClick={() => {
                                                        Projects.goToDemo(site.github)
                                                    }}>Github <i className="fas fa-arrow-right"/></button>
                                                )}
                                    </span>
                                        <AboutProject sites={this.sites} index={index}/>
                                    </div>
                                </div>

                            </div>

                        )
                    })}
                </div>
            </div>
        );
    }
}

export default Projects;

