import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from "./components/Navbar";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Projects from "./components/Projects";
import Contact from "./components/Contact";

function App() {
    return (
        <Router>
            <div className="App">
                <div className="left">
                    <section className="name">
                        <img src="../images/colton.png" alt="colton" id="logo"/>
                    </section>

                    <section className="contact-section">
                        <Contact/>
                    </section>

                    <section className="top-nav">
                        <Navbar/>
                    </section>
                </div>

                <div className="content">
                    <div className="view" id="view">
                        <Route render={({location}) => (
                            <TransitionGroup>
                                <CSSTransition
                                    key={location.key}
                                    timeout={500}
                                    classNames="item">
                                    <Switch location={location}>
                                        <Route exact
                                               path="/"
                                               component={Projects}/>
                                        <Route path="/projects"
                                               component={Projects}/>
                                        <Route path="*"
                                               component={Projects}/>
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        )}/>
                    </div>

                    <div className="footer">
                        <Contact/>
                    </div>

                </div>

                <div className="right">
                    <Navbar/>
                </div>
            </div>
        </Router>
    );
}

export default App;
