import React from 'react';
import '../styles/Navbar.css'

// let nav, tab;
// let navOpened = false;
// let event = event => {
//     if(navOpened) {
//         if (event.target !== nav) {
//             closeNav();
//         }
//     }
// };
//
// window.onload = () => {
//     nav = document.getElementById('nav-container');
//     tab = document.getElementById('tab');
// };
//
// document.addEventListener('keydown', event => {
//     if (event.key === "Escape" && navOpened) {
//         closeNav();
//     }
// });
//
// function openCloseNav() {
//     document.addEventListener('click', event);
//     navOpened ?  closeNav() : openNav();
// }
//
// function openNav() {
//     navOpened = true;
//     nav.style.animation = 'navIn .81s forwards';
//     tab.style.animation = 'tabShiftRight .82s forwards';
//     tab.innerHTML = "CLOSE";
// }
//
// function closeNav() {
//     navOpened = false;
//     nav.style.animation = 'navOut .8s forwards';
//     tab.style.animation = 'tabShiftLeft .37s forwards';
//     tab.innerHTML = "MENU";
//     document.removeEventListener('click', event)
// }


const Navbar = () => (
    <div>
        <div className="nav-container" id="nav-container">
            <ul className="menu-list">
                <a className="menu-list-item"
                   href="../images/colton-teefy-resume2019.pdf"
                   download="colton-teefy-resume2019.pdf">resume</a>

                <a className="menu-list-item"
                   href="mailto:coltonteefy@yahoo.com?subject=Let's Connect&body=Hello, I love your skills and think you are super!!">
                    contact
                </a>

            </ul>
        </div>
    </div>

);

export default Navbar;
