import React from 'react';
import '../styles/Contact.css'

function goToDemo(url) {
    window.open(url);
}

const Contact = () => (
    <div className="contact-container">
        <div className="links">
            <button className="contact-button" type="button" onClick={() => {
                goToDemo("https://www.linkedin.com/in/colton-teefy/")
            }}><i className="fab fa-linkedin-in"/></button>

        </div>
        <div className="links">
            <button className="contact-button" type="button" onClick={() => {
                goToDemo("https://github.com/coltonteefy")
            }}><i className="fab fa-github"/></button>

        </div>
        <div className="links">
            <button className="contact-button" type="button" onClick={() => {
                goToDemo("https://twitter.com/colton_teefy")
            }}><i className="fab fa-twitter"/></button>

        </div>
        <div className="links">
            <button className="contact-button" type="button" onClick={() => {
                goToDemo("https://codepen.io/COLTONTEEFY/")
            }}><i className="fab fa-codepen"/></button>

        </div>
        <div className="links">
            <button className="contact-button" type="button" onClick={() => {
                goToDemo("https://www.facebook.com/colton.teefy")
            }}><i className="fab fa-facebook-f"/></button>

        </div>
        <div className="links">
            <button className="contact-button" type="button" onClick={() => {
                goToDemo("https://www.instagram.com/cultteeee/")
            }}><i className="fab fa-instagram"/></button>

        </div>
    </div>
);

export default Contact;