import React from 'react';
import '../styles/AboutProject.css'


const AboutProject = (props) => (
    <div className="about-container">

        <section className="about-section">
            <h1 className="section-label">About</h1>
            <p className="content-text">{props.sites[props.index].about}</p>
        </section>
        <section className="about-section">
            <h1 className="section-label">Challenges</h1>
            <p className="content-text">{props.sites[props.index].challenges}</p>
        </section>

        <section className="about-section">
            <h1 className="section-label">Tools</h1>
            <div className="tools-container">
                {
                    props.sites[props.index].tools.map((res, index) => {
                        return (
                            <div key={index}>
                                <img src={res.path} alt="icon" className="tool-icon" key={index}/>
                                <h4 className="tool-name">{res.name}</h4>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    </div>
);

export default AboutProject;
